// import moment from 'moment';

export const DEFAULT_PROFILE = {
  profile_id: '',
  first_name: '',
  last_name: '',
  dob: null,
  phone: '',
  email: '',
  address: '',
  sex: ' ', // Must use a space as empty string is special to react-intl
  l1: '',
  l2: '',
  l3: '',
  l4: '',
  l5: '',
  lt1: null,
  lt2: null,
  lt3: null,
  lt4: null,
  lt5: null,
  r1: '',
  r2: '',
  r3: '',
  r4: '',
  r5: '',
  rt1: null,
  rt2: null,
  rt3: null,
  rt4: null,
  rt5: null,
};

export const DEFAULT_PROFILE_TEST = {
  profile_id: 'MST-000001',
  first_name: 'First Name',
  last_name: 'Last Name',
  dob: null,
  phone: '09000000',
  email: 'abc@abc.com',
  address: 'From some where in this tiny world',
  sex: ' ', // Must use a space as empty string is special to react-intl
  l1: 10,
  l2: 2,
  l3: 20,
  l4: 8,
  l5: 20,
  lt1: 'WT',
  lt2: 'WC',
  lt3: 'WD',
  lt4: 'AS',
  lt5: 'WT',
  r1: 10,
  r2: 6,
  r3: 20,
  r4: 4,
  r5: 10,
  rt1: 'WX',
  rt2: 'RL',
  rt3: 'WT',
  rt4: 'AR',
  rt5: 'AU',
};
