const moment = require('moment');

const dataLocale = require('../const/dateLocale');

const { WEEKDAYS_LONG, MONTHS, WEEKDAYS_SHORT, FIRST_DAY } = dataLocale;

// import {
//     WEEKDAYS_LONG,
//     MONTHS,
//     WEEKDAYS_SHORT,
//     FIRST_DAY,
// } from 'src/locale/dateLocale';

const dateformats = {
	DD_MM_YYYY: 'DD/MM/YYYY',
	DATETIME: 'hh:mm:ss DD/MM/YYYY',
	SHORT_DATETIME: 'hh:mm DD/MM/YYYY',
};

exports.dateformats = dateformats;
exports.formatDate = (date, format = dateformats.DATETIME) => {
	return date ? moment(date).format(format) : '';
};

exports.formatDateStr = (dateStr, format = dateformats.SHORT_DATETIME) => {
	if (dateStr) {
		return moment(dateStr, dateformats.DATETIME).format(format);
	}

	return '';
};

function formatDay(d, locale = 'en') {
	return `${WEEKDAYS_LONG[locale][d.getDay()]}, ${d.getDate()} ${
		MONTHS[locale][d.getMonth()]
	} ${d.getFullYear()}`;
}

function formatMonthTitle(d, locale = 'en') {
	return `${MONTHS[locale][d.getMonth()]} ${d.getFullYear()}`;
}

function formatWeekdayShort(i, locale) {
	return WEEKDAYS_SHORT[locale][i];
}

function formatWeekdayLong(i, locale) {
	return WEEKDAYS_SHORT[locale][i];
}

function getFirstDayOfWeek(locale) {
	return FIRST_DAY[locale];
}

function getMonths(locale) {
	return MONTHS[locale];
}

exports.localeUtils = {
	formatDay,
	formatMonthTitle,
	formatWeekdayShort,
	formatWeekdayLong,
	getFirstDayOfWeek,
	getMonths,
};
