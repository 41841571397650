import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Form } from 'react-bootstrap';
import { injectIntl } from 'react-intl';
import { login, getXsrfToken } from 'redux/actions';
import PropTypes from 'prop-types';
import { bindingFn } from 'utils/helper';
import messages from './messages';
import './style.scss';

class LoginPage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			email: '',
			password: '',
		};

		bindingFn.apply(this, [
			'handleSubmit',
			'validateForm',
			'handleEmailChange',
			'handlePasswordChange',
		]);
	}

	componentDidMount() {
		const { dispatch } = this.props;
		// get XSRF token from server
		dispatch(getXsrfToken());
	}

	handleSubmit(event) {
		event.preventDefault();

		const { email, password } = this.state;
		const { dispatch } = this.props;

		dispatch(login({ email, password }));
	}

	validateForm(event) {
		event.preventDefault();
		const { email, password } = this.state;
		return email.length > 0 && password.length > 0;
	}

	handleEmailChange(event) {
		this.setState({ email: event.target.value });
	}

	handlePasswordChange(event) {
		this.setState({ password: event.target.value });
	}

	render() {
		const { email, password } = this.state;
		const { intl } = this.props;

		return (
			<div className="login text-left">
				<Form onSubmit={this.handleSubmit}>
					<Form.Group controlId="email" className="mb-4">
						<Form.Label>{intl.formatMessage(messages.email)}</Form.Label>
						<Form.Control
							autoFocus
							type="email"
							value={email}
							onChange={this.handleEmailChange}
						/>
					</Form.Group>
					<Form.Group controlId="password" className="mb-4">
						<Form.Label>{intl.formatMessage(messages.password)}</Form.Label>
						<Form.Control
							value={password}
							onChange={this.handlePasswordChange}
							type="password"
						/>
					</Form.Group>
					<Button block size="lg" disabled={!this.validateForm} type="submit">
						{intl.formatMessage(messages.loginBtn)}
					</Button>
				</Form>
			</div>
		);
	}
}

// const mapDispatchToProps = dispatch => {
//   return {
//     login: data => dispatch(login(data))
//     // logout: () => dispatch({type:LOGOUT}),
//   };
// };

LoginPage.propTypes = {
	dispatch: PropTypes.func.isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	intl: PropTypes.object.isRequired,
};

export default injectIntl(connect()(LoginPage));
