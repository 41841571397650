import { combineReducers } from 'redux';
import { fromJS } from 'immutable';
import { connectRouter } from 'connected-react-router';
import auth from 'services/auth';
import { actionTypes } from './actions';

export const initialStore = {
	userInfo: undefined,
	// loading: false,
	error: {
		errMsg: undefined,

		// the request which receives the error
		actionReqErr: undefined,
	},
	requestSending: false,
	pdfResponse: undefined,
	snackbar: {
		msg: undefined,
		type: undefined,
	},
	isLoggedIn: auth.isLoggedIn(),
	// isLoggedIn: false
};

const initialState = fromJS(initialStore);
// const initialState = initialStore;

const reduxState = function mainReducer(state = initialState, action) {
	switch (action.type) {
		case actionTypes.FAILURE:
			return state.merge({ error: action.error });

		case actionTypes.LOGIN_SUCCESS:
			return state.merge({ userInfo: action.data, isLoggedIn: true });
		case actionTypes.SNACKBAR_SHOW:
			return state.merge({ snackbar: action.data });

		case actionTypes.LOGOUT_SUCCESS:
			return state.merge({ userInfo: undefined, isLoggedIn: false });

		case actionTypes.PDF_CREATE_FINISHED:
			return state.merge({ pdfResponse: action.data });
		case actionTypes.SENDING_REQUEST:
			return state.merge({ requestSending: action.sending });
		case actionTypes.REQUEST_ERROR:
			return state.merge({ error: action.error });
		case actionTypes.CLEAR_ERROR:
			return state.merge({ error: initialStore.get('error') });

		default:
			return state;
	}
};

const createRootReducer = history =>
	combineReducers({
		router: connectRouter(history),
		reduxState,
	});

export default createRootReducer;
