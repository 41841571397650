import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
	overrides: {
		MuiOutlinedInput: {
			input: {
				padding: '10px',
			},
		},
	},
});

export default theme;
