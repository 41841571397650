import { SERVER_URL } from 'const/url';
// import { get } from 'lodash';
// import { dateformats } from 'utils/date';
// import { dateToString } from 'utils/string';

function getErrorMessageFromYupValidation(errors) {
	return {
		raw: errors,
		message: 'YupValidationError',
	};
}

const pdfSrv = {
	createPdf: profile => {
		// eslint-disable-next-line no-async-promise-executor
		return new Promise(async (resolve, reject) => {
			const sendProfile = { ...profile };
			// sendProfile.dob = dateToString(profile.dob, dateformats.DD_MM_YYYY);

			try {
				const response = await fetch(SERVER_URL.CREATE_PDF, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify(sendProfile),
				});
				const result = await response.json();

				// we get the url of files
				if (!result.error) {
					resolve(result);
				} else if (result.error) {
					// validate error
					if (result.error.validate) {
						throw getErrorMessageFromYupValidation(
							result.error.validate
						);
					}
					throw result.error;
				}
			} catch (err) {
				console.error(err);
				reject(err);
			}
		});
	},
};

export default pdfSrv;
