// import * as Yup from 'yup';
// import moment from 'moment';
// // import * as fingerprintUtils from 'utils/fingerprint';

const Yup = require('yup');
const moment = require('moment');

const produce = (handside, n, yupFn) => {
	const result = {};

	for (let i = 0; i < n; i += 1) {
		result[`${handside}${i + 1}`] = yupFn.clone();
	}

	// console.log(result);
	return result;
};

// don't ever format this function to ES6
// Keep in mind that ES6 arrow function syntax cannot be used here
// as it retains the this from the enclosing lexical context.
// You won’t be able to access methods and variables exposed to you by Yup
// unless you use older function() {...} syntax.
Yup.addMethod(Yup.date, 'format', function format(formats, parseStrict) {
	return this.transform(function transform(value, originalValue) {
		if (this.isType(value)) return value;

		const newValue = moment(originalValue, formats, parseStrict);

		return newValue.isValid() ? newValue.toDate() : new Date('');
	});
});

const thisYear = new Date().getFullYear();
const startOfYear = new Date(`1/1/${thisYear}`);
const MIN_DATE = moment(startOfYear.valueOf())
	.subtract(40, 'y')
	.toDate();
const MAX_DATE = moment().toDate();

const basicRules = {
	profile_id: Yup.string()
		.max(13, 'validation_exceed_max13')
		.matches(/^[A-Z]{1,6}-\d{6}$/, {
			message: 'validation_invalid_profile_id_format',
			excludeEmptyString: true,
		})
		.required('validation_required'),
	first_name: Yup.string()
		.max(20, 'validation_exceed_max20')
		.matches(
			/^[a-zA-Z_\u00C0-\u00FF\u0100-\u01B0\u1EA0-\u1EFF ']*$/,
			'validation_invalid_name'
		)
		.required('validation_required'),
	last_name: Yup.string()
		.max(20, 'validation_exceed_max20')
		.matches(
			/^[a-zA-Z_\u00C0-\u00FF\u0100-\u01B0\u1EA0-\u1EFF ']*$/,
			'validation_invalid_name'
		)
		.required('validation_required'),
	email: Yup.string().email('validation_wrong_email_format'),
	phone: Yup.string()
		.test(
			'isMin',
			'validation_phone_below_min8',
			value => (!value) || value === '' || value.length >= 8,
		)
		.max(15, 'validation_phone_exceed_max15')
		.matches(
			/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/,
			{ 
				excludeEmptyString: true, 
				message: 'validation_invalid_phone'
			}
		),
	dob: Yup.date()
		.format('DD-MM-YYYY')
		.nullable()
		.typeError('validation_wrong_date')
		// MIN_DATE means the smallest date value can be accepted, or the max age
		// MAX_DATE means the largest date value can be accepted, or the min age
		.min(MIN_DATE, 'validation_max_age')
		.max(MAX_DATE, 'validation_min_age'),
	address: Yup.string().max(44, 'validation_exceed_max44'),
};
const fingerprintRule = Yup.number()
	.min(0, 'validation_fingerprint_below_min')
	.max(50, 'validation_fingerprint_exceed_max')
	.required('validation_required');

const leftFingerprintPointRules = produce('l', 5, fingerprintRule);
const rightFingerprintPointRules = produce('r', 5, fingerprintRule);

const fingerprintTypeRule = Yup.string()
	.nullable()
	.required('validation_required');
const leftFingerprintTypeRules = produce('lt', 5, fingerprintTypeRule);
const rightFingerprintTypeRules = produce('rt', 5, fingerprintTypeRule);

const validationSchema = Yup.object().shape(
	Object.assign(
		basicRules,
		leftFingerprintPointRules,
		rightFingerprintPointRules,
		leftFingerprintTypeRules,
		rightFingerprintTypeRules
	)
);

// export default validationSchema;
module.exports = validationSchema;
// module.exports = {
// 	validationSchema,
// };

// exports.validationSchema = validationSchema;
