exports.bindingFn = function bindingFn(...fnNames) {
	const self = this;
	fnNames.forEach(fnName => {
		self[fnName] = self[fnName].bind(self);
	});
};

// exports.promiseDispatchCreator = (...inputs) => {
// 	return dispatch => {
// 		return new Promise((resolve, reject) => {
// 			dispatch({
// 				...inputs
// 			});

// 			resolve();
// 		});
// 	};
// };

exports.isDevMode = () => process.env.NODE_ENV === 'development';

/**
 * Check and get the error message of the previous action
 * @param  {immultable object} stateErr the error object of the state
 * @param  {string} action action name
 */
exports.getErrorFromAction = (stateErr, action) => {
	return stateErr.get('errMsg') && stateErr.get('actionReqErr') === action
		? stateErr.get('errMsg')
		: undefined;
};
