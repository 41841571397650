import messages from './messages';
import { UI_URL } from '../const/url';

export default {
	HOME: {
		name: 'home',
		path: UI_URL.HOME,
		icon: 'home',
		message: messages.home,
	},
	CREATE_PDF: {
		name: 'createPdf',
		path: UI_URL.CREATE_PDF,
		icon: 'export',
		message: messages.createPdf,
	},
	// SEARCH: {
	//   name: 'search',
	//   path: '/search',
	//   icon: 'search',
	//   message: messages.search,
	// },
	// OTHERS: {
	//   name: 'others',
	//   path: '/others',
	//   icon: '',
	//   message: messages.others,
	// },
	LOGIN: {
		name: 'login',
		path: UI_URL.SIGN_IN,
		icon: '',
		message: messages.login,
	},
	LOGOUT: {
		name: 'logout',
		path: UI_URL.SIGN_OUT,
		icon: '',
		message: messages.logout,
	},
	ADMIN_PANEL: {
		name: 'adminPanel',
		path: UI_URL.ADMIN_PANEL_URL,
		icon: '',
		message: messages.adminPanel,
	},
	ABOUT: {
		name: 'about',
		path: UI_URL.ABOUT,
		icon: '',
		message: messages.about,
	},
};
