import { defineMessages } from 'react-intl';

const scope = 'app.routes';
const messages = defineMessages({
	home: {
		id: `${scope}.home`,
		defaultMessage: 'Trang Chủ',
	},
	createPdf: {
		id: `${scope}.createPdf`,
		defaultMessage: 'Tạo PDF',
	},
	// search: {
	//   id: `${scope}.search`,
	//   defaultMessage: 'Tìm Kiếm',
	// },
	// others: {
	//   id: `${scope}.others`,
	//   defaultMessage: 'Khác',
	// },
	login: {
		id: `${scope}.login`,
		defaultMessage: 'Đăng Nhập',
	},
	logout: {
		id: `${scope}.logout`,
		defaultMessage: 'Đăng Xuất',
	},
	adminPanel: {
		id: `${scope}.adminPanel`,
		defaultMessage: 'Bảng Điều Khiển Admin',
	},
	about: {
		id: `${scope}.about`,
		defaultMessage: 'Thông Tin',
	},
});

export default messages;
