/* eslint-disable no-async-promise-executor */
import fetch from 'cross-fetch';

import cookieSrv from './cookie';
import localStorageSrv from './localStorage';

import { SERVER_URL } from '../const/url';

const auth = {};

/**
 * Logs a user in, returning a promise with `{userInfo}` when done, otherwise error
 * @param  {string} email The email of the user
 * @param  {string} password The password of the user
 */
auth.login = async (email, password) => {
	return new Promise(async (resolve, reject) => {
		try {
			const response = await fetch(SERVER_URL.SIGN_IN, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					'x-csrf-token': cookieSrv.xsrfToken,
				},
				body: JSON.stringify({
					email,
					password,
				}),
			});

			const result = await response.json();

			if (!result.error) {
				cookieSrv.userToken = result.user._id;
				localStorageSrv.userInfo = result.user;
				resolve(result.user);
			} else {
				throw result.error;
			}
		} catch (err) {
			console.error(err);
			reject({ message: err });
		}
	});
};

/**
 * get xsrf token, returning a promise with `true` when done, otherwise error
 * @param  {string} email The email of the user
 * @param  {string} password The password of the user
 */
auth.getXsrfToken = () => {
	return new Promise(async (resolve, reject) => {
		try {
			const xsrfToken = await fetch(SERVER_URL.GET_XSRF_TOKEN);

			cookieSrv.xsrfToken = await xsrfToken.text();

			resolve(true);
		} catch (err) {
			console.error(err);
			reject(err);
		}
	});
};

/**
 * Logs the current user out
 */
auth.logout = () => {
	return new Promise(async (resolve, reject) => {
		try {
			const response = await fetch(SERVER_URL.SIGN_OUT);

			cookieSrv.clearUserToken();
			localStorageSrv.clearUserInfo();

			// to support logging out from all windows
			localStorageSrv.notifyLogout();

			resolve(response);
		} catch (err) {
			console.error(err);
			reject(err);
		}
	});
};

/**
 * Checks if a user is logged in
 */
auth.isLoggedIn = () => {
	return !!cookieSrv.userToken;
};

/**
 * Registers a user and then logs them in
 * @param  {string} username The username of the user
 * @param  {string} password The password of the user
 */
auth.register = (username, password) => {
	// Post a fake request
	return (
		fetch('/register', { username, password })
			// Log user in after registering
			.then(() => auth.login(username, password))
	);
};

export default auth;
