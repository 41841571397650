import React from 'react';
import PropTypes from 'prop-types';

class CustomErrMsg extends React.PureComponent {
  render() {
    const {
      intl, messages, children, ...rest
    } = this.props;
    const predefinedMsg = messages[children];

    const textMessage = predefinedMsg ? intl.formatMessage(predefinedMsg) : 'undefined';

    return <div {...rest}>{textMessage}</div>;
  }
}

CustomErrMsg.propTypes = {
  intl: PropTypes.any.isRequired,
  messages: PropTypes.object.isRequired,
  children: PropTypes.any.isRequired,
};

export default CustomErrMsg;
