import { all, call, put, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import auth from '../services/auth';
import pdfSrv from '../services/pdf';
import { UI_URL } from '../const/url';

import {
	actionTypes,
	// failure,
	loginSuccess,
	logoutSuccess,
	pdfCreateFinished,
	requestError,
	sendingRequest,
} from './actions';

function* loginSaga(payload) {
	// We send an action that tells Redux we're sending a request
	yield put(sendingRequest(true));

	try {
		// const history = useHistory();
		const { email, password } = payload.data;
		const userInfo = yield call(auth.login, email, password);

		// browserHistory.push(UI_URL.CREATE_PDF);
		yield put(loginSuccess(userInfo));
		yield put(push(UI_URL.HOME));

		return userInfo;
	} catch (error) {
		// If we get an error we send Redux the appropiate action and return
		yield put(requestError(error.message, actionTypes.LOGIN));

		return false;
	} finally {
		// When done, we tell Redux we're not in the middle of a request any more
		yield put(sendingRequest(false));
	}
}

function* getXsrfToken() {
	// We send an action that tells Redux we're sending a request
	yield put(sendingRequest(true));

	try {
		const response = yield call(auth.getXsrfToken);

		return response;
	} catch (error) {
		// If we get an error we send Redux the appropiate action and return
		yield put(requestError(error.message, actionTypes.GET_XSRF_TOKEN));

		return false;
	} finally {
		// When done, we tell Redux we're not in the middle of a request any more
		yield put(sendingRequest(false));
	}
}

function* genPdf(payload) {
	// We send an action that tells Redux we're sending a request
	yield put(sendingRequest(true));

	try {
		const profile = payload.data;

		// whether the pdf success or not, we just put back the result
		const pdfResponse = yield call(pdfSrv.createPdf, profile);
		yield put(pdfCreateFinished(pdfResponse));

		return pdfResponse;
	} catch (error) {
		// If we get an error we send Redux the appropiate action and return
		yield put(requestError(error.message, actionTypes.PDF_CREATE));

		return false;
	} finally {
		// When done, we tell Redux we're not in the middle of a request any more
		yield put(sendingRequest(false));
	}
}

function* logoutSaga() {
	// We send an action that tells Redux we're sending a request
	yield put(sendingRequest(true));

	try {
		const response = yield call(auth.logout);
		yield put(logoutSuccess());
		return response;
		// yield put(logoutSuccess(res));

		// force reload, otherwise the next time login will get error
		// since the csrf code only generated from server
		// location.href = UI_URL.SIGN_IN;
	} catch (error) {
		// If we get an error we send Redux the appropiate action and return
		yield put(requestError(error.message, actionTypes.LOGOUT));
		return false;
	} finally {
		// When done, we tell Redux we're not in the middle of a request any more
		yield put(sendingRequest(false));
	}
}

function* actionsWatcher() {
	yield all([
		takeLatest(actionTypes.LOGIN, loginSaga),
		takeLatest(actionTypes.GET_XSRF_TOKEN, getXsrfToken),
		takeLatest(actionTypes.LOGOUT, logoutSaga),
		takeLatest(actionTypes.PDF_CREATE, genPdf),
	]);
}

export default actionsWatcher;
