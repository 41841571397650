import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'formik';

import AutoComplete from '../../common/AutoComplete';

class FormikAutoComplete extends React.PureComponent {
	handleChange = value => {
		const { formik, name } = this.props;
		const { setFieldValue } = formik;
		setFieldValue(name, value);
	};

	render() {
		const {
			name,
			formik: { values, handleBlur, handleFocus },
			items,
			findItem,
			...rest
		} = this.props;

		const currentValue = values[name];
		return (
			<AutoComplete
				id={name}
				items={items}
				value={currentValue}
				onBlur={handleBlur}
				onFocus={handleFocus}
				onChange={this.handleChange}
				{...rest}
			/>
		);
	}
}

FormikAutoComplete.propTypes = {
	name: PropTypes.string.isRequired,
	formik: PropTypes.any.isRequired,
	className: PropTypes.string,
	placeholder: PropTypes.string,
	inputProps: PropTypes.any,
	items: PropTypes.arrayOf(
		PropTypes.shape({
			label: PropTypes.string.isRequired,
			value: PropTypes.any.isRequired,
		})
	),
	findItem: PropTypes.func,
	noOptionText: PropTypes.string,
};

FormikAutoComplete.defaultProps = {
	inputProps: null,
	items: [],
	findItem: id => id,
	className: '',
	placeholder: null,
	noOptionText: 'No options'
};

export default connect(FormikAutoComplete);
