export default [
	'WT',
	'WS',
	'WE',
	'WP',
	'WC',
	'WD',
	'WI',
	'WX',
	'UL',
	'RL',
	'AS',
	'AT',
	'AU',
	'AR',
	'AE',
];
