import { applyMiddleware, createStore, compose } from 'redux';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';

import createRootReducer from './reducer';
import rootSaga from './saga';

export const history = createBrowserHistory();

const bindMiddleware = middleware => {
	const composeEnhancers =
		window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
	return composeEnhancers(applyMiddleware(...middleware));
};

function configureStore() {
	const sagaMiddleware = createSagaMiddleware();
	const store = createStore(
		createRootReducer(history),
		bindMiddleware([sagaMiddleware, routerMiddleware(history)])
	);

	store.sagaTask = sagaMiddleware.run(rootSaga);

	return store;
}

export default configureStore;
