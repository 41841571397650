import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import FileSaver from 'file-saver';
import { UI_CONST, UI_URL } from 'const/url';
import ClientProfileForm from 'components/ClientProfileForm';
import { pdfCreate, showSnackbar, actionTypes } from 'redux/actions';
import { bindingFn, isDevMode, getErrorFromAction } from 'utils/helper';
import messages from './messages';

import './style.scss';

class PdfMakingPage extends Component {
	// toaster = null;

	constructor(props) {
		super(props);
		const { intl } = this.props;
		this.state = {
			submitting: false,
		};
		this.successMsg = intl.formatMessage(messages.exporting_succeed);
		this.errMsg = intl.formatMessage(messages.exporting_failed);
		bindingFn.apply(this, ['handleSubmit']);
	}

	componentDidUpdate(prevProps) {
		const { pdfResponse, error, dispatch } = this.props;
		// don't put it in render since it could loop forever
		if (pdfResponse !== prevProps.pdfResponse) {
			this.setState({ submitting: false });
			dispatch(
				showSnackbar(this.successMsg, UI_CONST.SNACKBAR.TYPE.SUCCESS)
			);

			if (isDevMode()) {
				pdfResponse.url = UI_URL.SERVER_LOCATION + pdfResponse.url;
			}
			FileSaver.saveAs(pdfResponse.url, pdfResponse.fileName);
		}

		if (error !== prevProps.error) {
			const err = getErrorFromAction(error, actionTypes.PDF_CREATE);
			if (err) {
				this.setState({ submitting: false });
				dispatch(
					showSnackbar(this.errMsg, UI_CONST.SNACKBAR.TYPE.ERROR)
				);
			}
		}
	}

	handleSubmit = async profile => {
		const { dispatch } = this.props;
		dispatch(pdfCreate(profile));
		this.setState({ submitting: true });
	};

	render() {
		const { submitting } = this.state;

		return (
			<div className="create-pdf-page">
				<ClientProfileForm
					onSubmit={this.handleSubmit}
					submitting={submitting}
					nextProfileId="MHS-000001"
				/>
			</div>
		);
	}
}

PdfMakingPage.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	pdfResponse: PropTypes.any,
	requestSending: PropTypes.bool,
	// eslint-disable-next-line react/forbid-prop-types
	error: PropTypes.object,
	dispatch: PropTypes.func.isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	intl: PropTypes.object.isRequired,
};

PdfMakingPage.defaultProps = {
	pdfResponse: undefined,
	requestSending: false,
	error: undefined,
};

// Which props do we want to inject, given the global state?
function mapStateToProps(state) {
	return {
		pdfResponse: state.reduxState.get('pdfResponse'),
		error: state.reduxState.get('error'),
		requestSending: state.reduxState.get('requestSending'),
	};
}

export default injectIntl(connect(mapStateToProps)(PdfMakingPage));
