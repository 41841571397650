import { defineMessages } from "react-intl";

const scope = "app.common.FormikSexSelect";

export default defineMessages({
  option_male: {
    id: `${scope}.option_male`,
    defaultMessage: "Nam",
  },
  option_female: {
    id: `${scope}.option_female`,
    defaultMessage: "Nữ",
  },
  option_empty: {
    id: `${scope}.option_empty`,
    defaultMessage: " ",
  },
});
