import React from 'react';
import { injectIntl } from 'react-intl';
import { Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Container from 'react-bootstrap/Container';
import Jumbotron from 'react-bootstrap/Jumbotron';

import NavBar from 'components/NavBar';
import Snackbar from 'components/mui/Snackbar';
import Loading from 'components/SpinnerLoading';
import ProtectedRoute from 'components/ProtectedRoute';
import routes from 'routers/routes';

import LoginPage from 'screens/LoginPage';
import HomePage from 'screens/HomePage';
import CreatePdfPage from 'screens/CreatePdfPage';
import LogoutComponent from 'components/common/LogoutComponent';
// import auth from "services/auth";
// import AboutPage from 'screens/AboutPage';
// import TestPage from 'screens/TestPage';
// import { showSnackbar } from 'redux/actions';
// import Button from 'react-bootstrap/Button';

import { isDevMode } from 'utils/helper';
import './App.scss';

function MyApp(props) {
	const { isLoggedIn } = props;

	return (
		<div className="App">
			<Loading />
			<NavBar isLoggedIn={isLoggedIn} />
			<Snackbar />
			<Container>
				<Jumbotron>
					<Switch>
						<ProtectedRoute
							path={routes.LOGIN.path}
							component={LoginPage}
							isAllowed
							isLoggedIn={isLoggedIn}
						/>
						<Route
							exact
							path={routes.HOME.path}
							component={HomePage}
							isAllowed
						/>
						{/* <ProtectedRoute
								path={routes.ABOUT.path}
								component={AboutPage}
								isAllowed={isLoggedIn}
							/> */}
						<ProtectedRoute
							exact
							path={routes.CREATE_PDF.path}
							component={CreatePdfPage}
							isAllowed={isLoggedIn}
						/>
						<ProtectedRoute
							exact
							path={routes.LOGOUT.path}
							component={LogoutComponent}
							isAllowed={isLoggedIn}
						/>
					</Switch>
				</Jumbotron>
			</Container>

			{/* <Button
					className="btn btn--dash btn--nav"
					onClick={() => {
						this.props.dispatch(showSnackbar("test message", "success"));
					}}
				>
					Test SnackBar
				</Button> */}
			<div className="footer bg-light text-black-50 font-italic w-100 d-flex align-items-center justify-content-center ">
				© 2019 {process.env.REACT_APP_SYSTEM_NAME} - Phiên bản{' '}
				{isDevMode() && 'DEVELOPMENT-'}v{process.env.REACT_APP_VERSION}-
				{process.env.REACT_APP_GIT_SHA}
			</div>
		</div>
	);
}

function mapStateToProps(state) {
	return {
		data: state.reduxState,
		isLoggedIn: state.reduxState.get('isLoggedIn'),
	};
}

MyApp.propTypes = {
	isLoggedIn: PropTypes.bool,
};

MyApp.defaultProps = {
	isLoggedIn: false,
};

export default injectIntl(connect(mapStateToProps)(MyApp));
