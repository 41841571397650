/* eslint-disable react/prefer-stateless-function */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { injectIntl } from 'react-intl';
import Button from '@material-ui/core/Button';
import moment from 'moment';
// import * as Yup from 'yup';

import { dateformats } from '../../utils/date';
import { isDevMode } from '../../utils/helper';
import * as fingerprintUtils from '../../utils/fingerprint';
// import { dateToString } from "../../utils/string";
import CustomErrMsg from '../common/CustomErrMsg';
// import FormikPersist from "../common/FormikPersistor";
import FormikMuiInput from '../mui/FormikMuiInput';
import FormikMuiDateInput from '../mui/FormikMuiDateInput';
import FormikMuiSexSelect from '../mui/FormikMuiSexSelect';
import MuiFingerprintField from '../mui/MuiFingerprintField';
import MuiCounterInput from '../mui/MuiCounterInput';
import { DEFAULT_PROFILE, DEFAULT_PROFILE_TEST } from './constants';
import messages from './messages';
import validationSchema from './yup';
import './style.scss';

// const thisYear = new Date().getFullYear();
// const startOfYear = new Date(`1/1/${thisYear}`);
// const MIN_DATE = moment(startOfYear.valueOf())
// 	.subtract(40, 'y')
// 	.toDate();
// const MAX_DATE = moment().toDate();

// const basicRules = {
// 	profile_id: Yup.string()
// 		.max(13, 'validation_exceed_max13')
// 		.matches(/^[A-Z]{1,6}-\d{6}$/, {
// 			message: 'validation_invalid_profile_id_format',
// 			excludeEmptyString: true,
// 		})
// 		.required('validation_required'),
// 	first_name: Yup.string()
// 		.max(20, 'validation_exceed_max20')
// 		.matches(
// 			/^[a-zA-Z_\u00C0-\u00FF\u0100-\u01B0\u1EA0-\u1EFF ']*$/,
// 			'validation_invalid_name'
// 		)
// 		.required('validation_required'),
// 	last_name: Yup.string()
// 		.max(20, 'validation_exceed_max20')
// 		.matches(
// 			/^[a-zA-Z_\u00C0-\u00FF\u0100-\u01B0\u1EA0-\u1EFF ']*$/,
// 			'validation_invalid_name'
// 		)
// 		.required('validation_required'),
// 	email: Yup.string().email('validation_wrong_email_format'),
// 	phone: Yup.string()
// 		.min(8, 'validation_phone_below_min8')
// 		.max(15, 'validation_phone_exceed_max15')
// 		.matches(
// 			/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/,
// 			'validation_invalid_phone'
// 		),
// 	dob: Yup.date()
// 		.nullable()
// 		.typeError('validation_wrong_date')
// 		// MIN_DATE means the smallest date value can be accepted, or the max age
// 		// MAX_DATE means the largest date value can be accepted, or the min age
// 		.min(MIN_DATE, 'validation_max_age')
// 		.max(MAX_DATE, 'validation_min_age'),
// 	address: Yup.string().max(44, 'validation_exceed_max44'),
// };
// const fingerprintRule = Yup.number()
// 	.min(0, 'validation_fingerprint_below_min')
// 	.max(50, 'validation_fingerprint_exceed_max')
// 	.required('validation_required');
// const leftFingerprintPointRules = fingerprintUtils.produce('l', 5, key => ({
// 	[`${key}`]: fingerprintRule,
// }));
// const rightFingerprintPointRules = fingerprintUtils.produce('r', 5, key => ({
// 	[`${key}`]: fingerprintRule,
// }));

// const fingerprintTypeRule = Yup.string()
// 	.nullable()
// 	.required('validation_required');
// const leftFingerprintTypeRules = fingerprintUtils.produce('lt', 5, key => ({
// 	[`${key}`]: fingerprintTypeRule,
// }));
// const rightFingerprintTypeRules = fingerprintUtils.produce('rt', 5, key => ({
// 	[`${key}`]: fingerprintTypeRule,
// }));

// const validationSchema = Yup.object().shape(
// 	Object.assign(
// 		{},
// 		{ ...basicRules },
// 		{ ...leftFingerprintPointRules },
// 		{ ...rightFingerprintPointRules },
// 		{ ...leftFingerprintTypeRules },
// 		{ ...rightFingerprintTypeRules }
// 	)
// );

// function convertDOBStringToDate(dobString) {
// 	if (dobString && dobString !== "") {
// 		return moment(dobString, dateformats.DD_MM_YYYY).toDate();
// 	}
// 	return null;
// }

// function convertDOBDateToString(dob) {
// 	return dateToString(dob, dateformats.DD_MM_YYYY);
// }

// function convertStringToObject(string) {
// 	const formdata = JSON.parse(string);
// 	const { values: profile } = formdata;
// 	profile.dob = convertDOBStringToDate(profile.dob);
// 	return formdata;
// }

// function convertObjectToString({ values: profile, errors }) {
// 	const dobString = convertDOBDateToString(profile.dob);
// 	const clone = Object.assign({}, profile, { dob: dobString });
// 	return JSON.stringify({ values: clone, errors });
// }

class ClientProfileForm extends PureComponent {
	nextProfileId = null;

	state = {
		submitting: this.props.submitting,
	};

	componentDidUpdate() {
		if (this.setSubmitting && this.props.submitting === false) {
			this.setSubmitting(this.props.submitting);
		}
	}

	setSubmitting = null;
	bindSetSubmitting = setSubmitting => {
		this.setSubmitting = setSubmitting;
	};

	onSubmit = values => {
		const { onSubmit } = this.props;
		const { dob, ...rest } = values;

		let formattedDob = null;
		if (dob) {
			formattedDob =
				dob instanceof moment
					? dob.format(dateformats.DD_MM_YYYY)
					: dob;
		}

		onSubmit({
			...rest,
			dob: formattedDob,
		});
	};

	render() {
		const { intl, nextProfileId } = this.props;

		const tempDefaultProfile = isDevMode()
			? DEFAULT_PROFILE_TEST
			: DEFAULT_PROFILE;

		const defaultProfile = {
			profile_id: nextProfileId,
			...tempDefaultProfile,
		};

		return (
			<Formik
				initialValues={defaultProfile}
				validationSchema={validationSchema}
				onSubmit={this.onSubmit}
				validateOnChange={false}
			>
				{({ isSubmitting, setSubmitting }) => {
					this.bindSetSubmitting(setSubmitting);
					return (
						<div className="client-profile-container">
							<Form className="client-profile-form">
								{/* <FormikPersist
                name="ProfileForm"
                initialValues={profile}
                converter={{
                  toStringFunc: convertObjectToString,
                  toObjectFunc: convertStringToObject
                }}
              /> */}
								<div className="base-info-container">
									<div className="row">
										<div className="col-md-6 col-sm-12">
											<div className="row">
												<label
													className="col-md-4 col-sm-3 lb-t-right required"
													htmlFor="profile_id"
												>
													{intl.formatMessage(
														messages.profile_id
													)}
												</label>
												<div className="col-md-8 col-sm-9">
													<FormikMuiInput
														name="profile_id"
														className="w-100"
													/>
												</div>
											</div>
											<div className="row">
												<div className="col-md-4 col-sm-3">
													&nbsp;
												</div>
												<ErrorMessage
													component={CustomErrMsg}
													intl={intl}
													messages={messages}
													name="profile_id"
													className="col-md-8 col-sm-9 error"
												/>
											</div>
										</div>
										<div className="col-md-6"></div>
									</div>

									<div className="row">
										<div className="col-md-6 col-sm-12">
											<div className="row">
												<label
													className="col-md-4 col-sm-3 lb-t-right required"
													htmlFor="last_name"
												>
													{intl.formatMessage(
														messages.last_name
													)}
												</label>
												<div className="col-md-8 col-sm-9">
													<FormikMuiInput
														name="last_name"
														className="w-100"
														type="text"
													/>
												</div>
											</div>
											<div className="row">
												<div className="col-md-4 col-sm-3">
													&nbsp;
												</div>
												<ErrorMessage
													component={CustomErrMsg}
													intl={intl}
													messages={messages}
													name="last_name"
													className="col-md-8 col-sm-9 error"
												/>
											</div>
										</div>
										<div className="col-md-6 col-sm-12">
											<div className="row">
												<label
													className="col-md-3 col-sm-3 lb-t-right required"
													htmlFor="first_name"
												>
													{intl.formatMessage(
														messages.first_name
													)}
												</label>
												<div className="col-md-9 col-sm-9">
													<FormikMuiInput
														name="first_name"
														className="w-100"
														type="text"
													/>
												</div>
											</div>
											<div className="row">
												<div className="col-md-3 col-sm-3">
													&nbsp;
												</div>
												<ErrorMessage
													component={CustomErrMsg}
													intl={intl}
													messages={messages}
													name="first_name"
													className="col-md-9 col-sm-9 error"
												/>
											</div>
										</div>
									</div>

									<div className="row">
										<div className="col-md-6 col-sm-12">
											<div className="row">
												<label
													className="col-md-4 col-sm-3 lb-t-right"
													htmlFor="phone"
												>
													{intl.formatMessage(
														messages.phone
													)}
												</label>
												<div className="col-md-8 col-sm-9">
													<FormikMuiInput
														name="phone"
														className="w-100"
														type="text"
													/>
												</div>
											</div>
											<div className="row">
												<div className="col-md-4 col-sm-3">
													&nbsp;
												</div>
												<ErrorMessage
													component={CustomErrMsg}
													intl={intl}
													messages={messages}
													name="phone"
													className="col-md-8 col-sm-9 error"
												/>
											</div>
										</div>
										<div className="col-md-6 col-sm-12">
											<div className="row">
												<label
													className="col-md-3 col-sm-3 lb-t-right"
													htmlFor="email"
												>
													{intl.formatMessage(
														messages.email
													)}
												</label>
												<div className="col-md-9 col-sm-9">
													<FormikMuiInput
														name="email"
														className="w-100"
														type="email"
													/>
												</div>
											</div>
											<div className="row">
												<div className="col-md-3 col-sm-3">
													&nbsp;
												</div>
												<ErrorMessage
													component={CustomErrMsg}
													intl={intl}
													messages={messages}
													name="email"
													className="col-md-9 col-sm-9 error"
												/>
											</div>
										</div>
									</div>
									<div className="row">
										<div className="col-md-6 col-sm-12">
											<div className="row">
												<label
													className="col-md-4 col-sm-3 lb-t-right"
													htmlFor="dob"
												>
													{intl.formatMessage(
														messages.dob
													)}
												</label>
												<div className="col-md-8 col-sm-9">
													<FormikMuiDateInput
														name="dob"
														datePickerClassName="w-100"
														maxDate={new Date()}
														error={false}
														helperText={null}
													/>
												</div>
											</div>
											<div className="row">
												<div className="col-md-4 col-sm-3">
													&nbsp;
												</div>
												<ErrorMessage
													component={CustomErrMsg}
													intl={intl}
													messages={messages}
													name="dob"
													className="col-md-8 col-sm-9 error"
												/>
											</div>
										</div>
										<div className="col-md-6 col-sm-12">
											<div className="row">
												<label
													className="col-md-3 col-sm-3 lb-t-right"
													htmlFor="sex"
												>
													{intl.formatMessage(
														messages.sex
													)}
												</label>
												<div className="col-md-9 col-sm-9">
													<FormikMuiSexSelect
														name="sex"
														className="w-100 text-left"
													/>
													{/* <Field
														id="address"
														component={
															MuiCounterInput
														}
														className="w-100"
														name="address"
														type="text"
														max={44}
													/> */}
												</div>
											</div>
											<div className="row">
												<div className="col-md-3 col-sm-3">&nbsp;</div>
												<div className="col-md-9 col-sm-9"></div>
											</div>
										</div>
									</div>

									<div className="row">
										<label
											className="col-md-2 col-sm-3 lb-t-right"
											htmlFor="address"
										>
											{intl.formatMessage(
												messages.address
											)}
										</label>
										<div className="col-md-10 col-sm-9">
											<Field
												id="address"
												component={MuiCounterInput}
												className="w-100"
												name="address"
												type="text"
												max={44}
											/>
										</div>
									</div>

									<div className="row">
										<div className="col-md-2 col-sm-3">&nbsp;</div>
										<ErrorMessage
											component={CustomErrMsg}
											intl={intl}
											messages={messages}
											name="address"
											className="col-md-10 col-sm-9 error"
										/>
									</div>
								</div>

								<div className="border-top my-3" />
								<div className="fingerprint-fields-wrapper">
									<div className="row">
										<div className="col-md-6 col-sm-12">
											{fingerprintUtils.produceArray(
												'l',
												5,
												(key, index) => (
													<MuiFingerprintField
														key={key}
														handSide="l"
														fingerIndex={index + 1}
														label={intl.formatMessage(
															messages[key]
														)}
														errorMessages={messages}
													/>
												)
											)}
										</div>
										<div className="col-md-6 col-sm-12">
											{fingerprintUtils.produceArray(
												'r',
												5,
												(key, index) => (
													<MuiFingerprintField
														key={key}
														handSide="r"
														fingerIndex={index + 1}
														label={intl.formatMessage(
															messages[key]
														)}
														errorMessages={messages}
													/>
												)
											)}
										</div>
									</div>
								</div>

								<div className="action-container">
									<Button
										variant="contained"
										color="primary"
										type="submit"
										disabled={isSubmitting}
									>
										{intl.formatMessage(
											messages.button_save_export
										)}
									</Button>
								</div>
							</Form>
						</div>
					);
				}}
			</Formik>
		);
	}
}

ClientProfileForm.propTypes = {
	intl: PropTypes.any.isRequired,
	profile: PropTypes.object,
	onSubmit: PropTypes.func,
	nextProfileId: PropTypes.string.isRequired,
	submitting: PropTypes.bool.isRequired,
};

ClientProfileForm.defaultProps = {
	onSubmit: () => {},
	profile: DEFAULT_PROFILE,
	checkProfileId: () => false,
};

export default injectIntl(ClientProfileForm);
