import localStorageSrv from './localStorage';
import { UI_CONST } from '../const/url';

class UserSrv {
	isAdmin() {
		return this.getRole() === UI_CONST.USER.ROLE.ADMIN;
	}

	getRole() {
		return localStorageSrv.userInfo.role;
	}
}

export default new UserSrv();
