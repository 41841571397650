import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
// import Button from 'react-bootstrap/Button';
import { NavLink, Link } from 'react-router-dom';
import routes from 'routers/routes';

import userSrv from 'services/user';
import './style.scss';

function AppHeader(props) {
	const { intl, isLoggedIn, pathname } = props;

	const isHideLoginBtn = pathname !== routes.LOGIN.path;
	// const { userInfo } = props;

	const navButtons = isLoggedIn && (
		<div>
			<NavLink
				className="btn btn--dash btn--nav"
				to={routes.CREATE_PDF.path}
				activeClassName="selected"
			>
				{intl.formatMessage(routes.CREATE_PDF.message)}
			</NavLink>
			{userSrv.isAdmin() && (
				<a className="btn btn--dash btn--nav" href={routes.ADMIN_PANEL.path}>
					{intl.formatMessage(routes.ADMIN_PANEL.message)}
				</a>
			)}
		</div>
	);

	return (
		<Navbar bg="light" variant="light" className="nav-style">
			<Container>
				<Navbar.Brand>
					<Link to={routes.HOME.path}>
						<img src="/images/logo.png" alt="Wisdomology's logo" />
					</Link>
				</Navbar.Brand>
				<Navbar.Toggle aria-controls="basic-navbar-nav" />
				<Navbar.Collapse
					id="basic-navbar-nav"
					className="justify-content-between"
				>
					<Nav className="d-flex justify-content-end">{navButtons}</Nav>
					{/* <Form inline>
							{userInfo ? (
								<NavDropdown title={userInfo.name.full} id="basic-nav-dropdown">
									<NavDropdown.Item href="/logout">Đăng Xuất</NavDropdown.Item>
								</NavDropdown>
							) : (
								""
							)}
						</Form> */}

					{isLoggedIn ? (
						<Link
							to={routes.LOGOUT.path}
							className="btn btn-outline-secondary"
							role="button"
						>
							{intl.formatMessage(routes.LOGOUT.message)}
						</Link>
					) : (
						<>
							{isHideLoginBtn && (
								<Link
									to={routes.LOGIN.path}
									className="btn btn-outline-primary"
									role="button"
								>
									{intl.formatMessage(routes.LOGIN.message)}
								</Link>
							)}
						</>
					)}
				</Navbar.Collapse>
			</Container>
		</Navbar>
	);
}

AppHeader.propTypes = {
	isLoggedIn: PropTypes.bool,
	pathname: PropTypes.string,
	// eslint-disable-next-line react/forbid-prop-types
	intl: PropTypes.object.isRequired,
};

AppHeader.defaultProps = {
	isLoggedIn: false,
	pathname: '',
};

const mapStateToProps = state => ({
	pathname: state.router.location.pathname,
});

export default injectIntl(connect(mapStateToProps)(AppHeader));
