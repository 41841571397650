import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'formik';
import {
	MuiPickersUtilsProvider,
	KeyboardDatePicker,
} from '@material-ui/pickers';
import Grid from '@material-ui/core/Grid';
// pick a date util library
import MomentUtils from '@date-io/moment';

import { dateformats } from '../../../utils/date';

class FormikMuiDateInput extends React.PureComponent {
	input = null;

	refHandlers = {
		input: ref => (this.input = ref),
	};

	handleBlur = e => {
		const { formik } = this.props;
		formik.handleBlur(e);
	};

	handleChange = selectedDate => {
		const {
			name,
			formik: { setFieldValue },
		} = this.props;
		setFieldValue(name, selectedDate);
	};

	handlePopoverClose = e => {
		if (this.input) {
			this.input.focus();
		}
	};

	render() {
		const {
			formik: { values },
			name,
			datePickerClassName,
			...rest
		} = this.props;

		return (
			<MuiPickersUtilsProvider utils={MomentUtils}>
				<Grid container justify="space-around">
					<KeyboardDatePicker
						disableToolbar
						variant="inline"
						inputVariant="outlined"
						format={dateformats.DD_MM_YYYY}
						id={name}
						className={datePickerClassName}
						value={values[name]}
						onChange={this.handleChange}
						KeyboardButtonProps={{
							'aria-label': 'birthday',
						}}
						{...rest}
					/>
				</Grid>
			</MuiPickersUtilsProvider>
		);
	}
}

FormikMuiDateInput.propTypes = {
	formik: PropTypes.any.isRequired,
	name: PropTypes.string.isRequired,
	datePickerClassName: PropTypes.string,
};

FormikMuiDateInput.defaultProps = {
	datePickerClassName: null,
};

export default connect(FormikMuiDateInput);
