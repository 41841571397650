import ls from "local-storage";
import { UI_CONST } from "../const/url";

const localStorageSrv = {
	get userInfo() {
		return ls.get(UI_CONST.LOCAL_STORAGE_USER);
	},

	set userInfo(data) {
		ls.set(UI_CONST.LOCAL_STORAGE_USER, data);
	},

	clearUserInfo: () => {
		ls.remove(UI_CONST.LOCAL_STORAGE_USER);
	},

	// to support logging out from all of browser's tabs
	notifyLogout: () => {
		ls.set(UI_CONST.EVENT_LOGGED_OUT, Date.now());
	}
};

export default localStorageSrv;
