const defaultProducer = (key, index) => ({});

export const produce = (handside, n, producer = defaultProducer) =>
	Array.from(Array(n).keys()).reduce((result, i) => {
		const key = `${handside}${i + 1}`;
		const product = producer(key, i);
		return {
			...result,
			...product,
		};
	}, {});

export const produceArray = (handside, n, producer = defaultProducer) =>
	Array.from(Array(n).keys()).map(i => {
		const key = `${handside}${i + 1}`;
		return producer(key, i);
	});
