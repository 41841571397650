/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { UI_URL } from 'const/url';

function ProtectedRoute(props) {
	const { component: Component, isAllowed, ...rest } = props;

	return (
		<Route
			{...rest}
			render={propsChild =>
				isAllowed ? (
					<Component {...propsChild} />
				) : (
					<Redirect to={UI_URL.SIGN_IN} />
				)
			}
		/>
	);
}

ProtectedRoute.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	component: PropTypes.any.isRequired,
	isAllowed: PropTypes.bool.isRequired,
};

export default ProtectedRoute;
