import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { logout } from 'redux/actions';

class LogoutComponent extends React.Component {
	componentDidMount() {
		const { dispatch } = this.props;
		dispatch(logout());
	}

	render() {
		return '';
	}
}

LogoutComponent.propTypes = {
	dispatch: PropTypes.func.isRequired,
};

export default connect()(LogoutComponent);
