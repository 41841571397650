import { defineMessages } from "react-intl";

const scope = "app.createPdf";

export default defineMessages({
	exporting_failed: {
		id: `${scope}.exporting_failed`,
		defaultMessage: "Tạo PDF thất bại. Xin thử lại sau."
	},
	exporting_succeed: {
		id: `${scope}.exporting_succeed`,
		defaultMessage: "Tạo PDF thành công."
	},

	dialog_title: {
		id: `${scope}.dialog_title`,
		defaultMessage: "Thông Báo"
	},
	dialog_btn_confirm: {
		id: `${scope}.alert_btn_confirm`,
		defaultMessage: "Đồng ý"
	},
	dialog_btn_cancel: {
		id: `${scope}.alert_btn_cancel`,
		defaultMessage: "Hủy"
	}
});
