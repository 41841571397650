import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import clsx from 'clsx';
import { Snackbar, SnackbarContent, IconButton } from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
// import InfoIcon from '@material-ui/icons/Info';
// import WarningIcon from '@material-ui/icons/Warning';
import CloseIcon from '@material-ui/icons/Close';
import { showSnackbar } from 'redux/actions';
import { UI_CONST } from 'const/url';

const variantIcon = {};
variantIcon[UI_CONST.SNACKBAR.TYPE.SUCCESS] = CheckCircleIcon;
// variantIcon[UI_CONST.SNACKBAR.TYPE.SUCCESS] = WarningIcon;
variantIcon[UI_CONST.SNACKBAR.TYPE.ERROR] = ErrorIcon;
// variantIcon[UI_CONST.SNACKBAR.TYPE.SUCCESS] = InfoIcon;

// for synchronizing the name with UI_CONST
const useStyles = makeStyles(theme => {
	const styleObj = {
		close: {
			padding: theme.spacing(0.5),
		},
		icon: {
			fontSize: 20,
		},
		iconVariant: {
			opacity: 0.9,
			marginRight: theme.spacing(1),
		},
		message: {
			// display: 'flex',
			// alignItems: 'center',
		},
	};
	styleObj[UI_CONST.SNACKBAR.TYPE.SUCCESS] = {
		backgroundColor: green[600],
	};
	styleObj[UI_CONST.SNACKBAR.TYPE.ERROR] = {
		backgroundColor: theme.palette.error.dark,
	};

	return styleObj;
});

function SimpleSnackbar(props) {
	const classes = useStyles();
	const [open, setOpen] = React.useState(false);
	const { className, msg, type, duration } = props;
	const Icon = variantIcon[type];

	const handleClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}

		// we clear the redux store
		props.dispatch(showSnackbar(undefined, undefined));
		setOpen(false);
	};

	// https://reactjs.org/docs/hooks-reference.html#conditionally-firing-an-effect
	React.useEffect(() => {
		if (msg) setOpen(true);
		return () => {
			// setOpen(false);
		};
	}, [msg]);

	return msg ? (
		<div>
			<Snackbar
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
				open={open}
				autoHideDuration={duration}
				onClose={handleClose}
			>
				<SnackbarContent
					className={clsx(classes[type], className)}
					aria-describedby="client-snackbar"
					message={
						<span id="client-snackbar" className={classes.message}>
							<Icon className={clsx(classes.icon, classes.iconVariant)} />
							{msg}
						</span>
					}
					action={[
						<IconButton
							key="close"
							aria-label="close"
							color="inherit"
							onClick={handleClose}
						>
							<CloseIcon className={classes.close} />
						</IconButton>,
					]}
				/>
			</Snackbar>
		</div>
	) : (
		''
	);
}

SimpleSnackbar.propTypes = {
	msg: PropTypes.string,
	duration: PropTypes.number,
	className: PropTypes.string,
	dispatch: PropTypes.func.isRequired,
	type: PropTypes.oneOf([
		UI_CONST.SNACKBAR.TYPE.SUCCESS,
		UI_CONST.SNACKBAR.TYPE.ERROR,
	]),
};

SimpleSnackbar.defaultProps = {
	duration: UI_CONST.NOTIFY_DEFAULT_TIMEOUT,
	className: '',
	msg: undefined,
	type: undefined,
};

function mapStateToProps(state) {
	return {
		msg: state.reduxState.getIn(['snackbar', 'msg']),
		type: state.reduxState.getIn(['snackbar', 'type']),
	};
}

export default connect(mapStateToProps)(SimpleSnackbar);
