const isDevMode = () => process.env.NODE_ENV === 'development';

const adminPath = '/cms';
const createPdfPath = '/createPdf';
const apiPath = '/api';
const serverLocation = 'http://localhost:2900';
const clientLocation = 'http://localhost:3000';
const adminPanelUrl = (isDevMode() ? serverLocation : '') + adminPath;
let clientUrl = '/';
if (isDevMode()) clientUrl = clientLocation;
// console.log("is dev mode: %j", isDevMode());

const UI_URL = {
	CLIENT_LOCATION: clientUrl,
	HOME: '/',
	CREATE_PDF: createPdfPath,
	ABOUT: '/about',
	SIGN_IN: '/login',
	SIGN_OUT: '/logout',
	ADMIN_PANEL_URL: adminPanelUrl,
	SERVER_LOCATION: serverLocation,
};

// console.log(UI_URL);
const SERVER_URL = {
	ADMIN_PATH: adminPath,
	API_PATH: apiPath,
	CREATE_PDF: apiPath + createPdfPath,
	GET_XSRF_TOKEN: `${apiPath}/genXsrfToken`,

	// Eg: /cms/api/session/signin
	SIGN_IN: `${adminPath + apiPath}/session/signin`,
	SIGN_OUT: `${adminPath}/signout`,
};

const UI_CONST = {
	USER: {
		ROLE: {
			ADMIN: 'admin',
			STAFF: 'staff',
		},
	},
	SNACKBAR: {
		TYPE: {
			SUCCESS: 'success',
			ERROR: 'error',
		},
	},
	LOCAL_STORAGE_USER: 'userInfo',
	TOKEN_LOGGED_IN: 'logged_in',
	EVENT_LOGGED_OUT: 'logged_out',
	NOTIFY_DEFAULT_TIMEOUT: 6000, //	ms
};

exports.UI_URL = UI_URL;
exports.SERVER_URL = SERVER_URL;
exports.UI_CONST = UI_CONST;

// export default { UI_URL, SERVER_URL, UI_CONST };
