import { defineMessages } from 'react-intl';

const scope = 'app.LoginPage';

export default defineMessages({
	loginBtn: {
		id: `${scope}.loginBtn`,
		defaultMessage: 'Đăng Nhập',
	},
	email: {
		id: `${scope}.email`,
		defaultMessage: 'Email',
	},
	password: {
		id: `${scope}.password`,
		defaultMessage: 'Mật Khẩu',
	},

	// validation_required: {
	//   id: `${scope}.validation_required`,
	//   defaultMessage: "Không để trống"
	// },
	// validation_exceed_max: {
	//   id: `${scope}.validation_exceed_max`,
	//   defaultMessage: "Vượt quá 50 kí tự"
	// },
	// validation_exceed_max20: {
	//   id: `${scope}.validation_exceed_max20`,
	//   defaultMessage: "Vượt quá 20 kí tự"
	// },
	// validation_exceed_max44: {
	//   id: `${scope}.validation_exceed_max44`,
	//   defaultMessage: "Vượt quá 44 kí tự"
	// },
	// validation_phone_below_min8: {
	//   id: `${scope}.validation_phone_below_min8`,
	//   defaultMessage: "Số điện thoại tối thiểu 8 kí tự"
	// },
	// validation_phone_exceed_max15: {
	//   id: `${scope}.validation_phone_exceed_max15`,
	//   defaultMessage: "Số điện thoại tối đa 15 kí tự"
	// },
	// validation_exceed_max13: {
	//   id: `${scope}.validation_exceed_max13`,
	//   defaultMessage: "Vượt quá 13 kí tự"
	// },
	// validation_fingerprint_exceed_max: {
	//   id: `${scope}.validation_fingerprint_exceed_max`,
	//   defaultMessage: "Giá trị không được lớn hơn 50"
	// },
	// validation_fingerprint_below_min: {
	//   id: `${scope}.validation_fingerprint_below_min`,
	//   defaultMessage: "Giá trị không được nhỏ hơn 0"
	// },
	// validation_wrong_email_format: {
	//   id: `${scope}.validation_wrong_email_format`,
	//   defaultMessage: "Email sai định dạng"
	// },
	// validation_wrong_date: {
	//   id: `${scope}.validation_wrong_date`,
	//   defaultMessage: "Ngày tháng không hợp lệ"
	// },
	// validation_invalid_profile_id_format: {
	//   id: `${scope}.validation_invalid_profile_id_format`,
	//   defaultMessage: "Định dạng:<1-6 kí tự hoa>-<6 số>"
	// },
	// validation_min_age: {
	//   id: `${scope}.validation_min_age`,
	//   defaultMessage: "Không nhỏ hơn 0 tuổi"
	// },
	// validation_max_age: {
	//   id: `${scope}.validation_max_age`,
	//   defaultMessage: "Không lớn hơn 40 tuổi"
	// },
	// validation_invalid_name: {
	//   id: `${scope}.validation_invalid_name`,
	//   defaultMessage: "Chỉ dùng chữ hoa, chữ thường, dấu ' "
	// },
	// validation_invalid_phone: {
	//   id: `${scope}.validation_invalid_phone`,
	//   defaultMessage: "Số điện thoại không hợp lệ"
	// }
});
