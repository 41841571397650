import { fromJS } from 'immutable';
export const actionTypes = {
	LOGIN: 'LOGIN',
	LOGIN_SUCCESS: 'LOGIN_SUCCESS',
	SNACKBAR_SHOW: 'SNACKBAR_SHOW',
	GET_XSRF_TOKEN: 'GET_XSRF_TOKEN',
	LOGOUT: 'LOGOUT',
	LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
	PDF_CREATE: 'PDF_CREATE',
	PDF_CREATE_FINISHED: 'PDF_CREATE_FINISHED',
	FAILURE: 'FAILURE',
	SENDING_REQUEST: 'SENDING_REQUEST',
	REQUEST_ERROR: 'REQUEST_ERROR',
	CLEAR_ERROR: 'CLEAR_ERROR',
};

export function failure(error) {
	return {
		type: actionTypes.FAILURE,
		error,
	};
}

export function login(data) {
	return { type: actionTypes.LOGIN, data };
}

export function showSnackbar(msg, type) {
	const data = fromJS({ msg, type });
	return { type: actionTypes.SNACKBAR_SHOW, data };
}

export function getXsrfToken() {
	return { type: actionTypes.GET_XSRF_TOKEN };
}

export function loginSuccess(data) {
	return { type: actionTypes.LOGIN_SUCCESS, data };
}

export function logout() {
	return { type: actionTypes.LOGOUT };
}

export function logoutSuccess() {
	return { type: actionTypes.LOGOUT_SUCCESS };
}

export function pdfCreate(data) {
	return { type: actionTypes.PDF_CREATE, data };
}

export function pdfCreateFinished(data) {
	return {
		type: actionTypes.PDF_CREATE_FINISHED,
		data,
	};
}

/**
 * Sets the `currentlySending` state, which displays a loading indicator during requests
 * @param  {boolean} sending True means we're sending a request, false means we're not
 */
export function sendingRequest(sending) {
	return { type: actionTypes.SENDING_REQUEST, sending };
}

/**
 * Sets the `error` state to the error received
 * @param  {object} error The error we got when trying to make the request
 */
export function requestError(errMsg, actionReqErr) {
	const error = fromJS({ errMsg, actionReqErr });
	return { type: actionTypes.REQUEST_ERROR, error };
}

/**
 * Sets the `error` state as empty
 */
export function clearError() {
	return { type: actionTypes.CLEAR_ERROR };
}
