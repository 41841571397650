import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import './style.scss';

function AboutPage(props) {
	// constructor(props) {
	// 	super(props);
	// }

	const { isLoggedIn } = props;

	return (
		<main role="main" className="home-page container">
			{isLoggedIn ? (
				<>
					<h1 className="mt-5 text-capitalize">
						Hệ thống tạo PDF sinh trắc vân tay
					</h1>
					<p className="lead">
						Một sản phẩm thuộc <code> Wisdomology.</code>
					</p>
				</>
			) : (
				<p className="lead text-info">
					Vui lòng đăng nhập để sử dụng website.
				</p>
			)}
		</main>
	);
}

AboutPage.propTypes = {
	isLoggedIn: PropTypes.bool,
};

AboutPage.defaultProps = {
	isLoggedIn: false,
};

function mapStateToProps(state) {
	return { isLoggedIn: state.reduxState.get('isLoggedIn') };
}

export default connect(mapStateToProps)(AboutPage);
