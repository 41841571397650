import { defineMessages } from 'react-intl';

const scope = 'app.common.FingerprintField';

export default defineMessages({
	placeholder_fingerprinttype: {
		id: `${scope}.placeholder_fingerprinttype`,
		defaultMessage: 'Chủng ...',
	},
	no_result: {
		id: `${scope}.no_result`,
		defaultMessage: 'Không có',
	},
});
