import React from 'react';
import PropTypes from 'prop-types';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';

const countInputValue = value => (value ? value.length : 0);

class MuiCounterInput extends React.Component {
	state = {
		count: 0,
	};

	static getDerivedStateFromProps(nextProps, prevState) {
		const { count } = prevState;
		const {
			field: { value: nextValue },
		} = nextProps;

		const recount = countInputValue(nextValue);

		if (count !== recount) {
			return { count: recount };
		}

		return null;
	}

	componentDidMount() {
		const { count } = this.state;
		const {
			field: { value },
		} = this.props;

		const recount = countInputValue(value);

		if (count !== recount) {
			this.setState({ count: recount });
		}
	}

	handleInputChange = e => {
		const {
			field: { onChange },
		} = this.props;

		const count = countInputValue(e.target.value);

		this.setState({ count });

		onChange(e);
	};

	render() {
		const { count } = this.state;
		const { field, max, name, className } = this.props;

		const { onChange, ...restFieldProps } = field;

		return (
			<OutlinedInput
				id={name}
				className={className}
				variant="outlined"
				onChange={this.handleInputChange}
				endAdornment={
					<InputAdornment position="end">{`${count}/${max}`}</InputAdornment>
				}
				max={max}
				{...restFieldProps}
			/>
		);
	}
}

MuiCounterInput.propTypes = {
	max: PropTypes.number.isRequired,
	className: PropTypes.string,
	field: PropTypes.any.isRequired,
};

MuiCounterInput.defaultProps = {
	className: null,
};

export default MuiCounterInput;
