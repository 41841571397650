exports.WEEKDAYS_LONG = {
	en: [
		'Sunday',
		'Monday',
		'Tuesday',
		'Wednesday',
		'Thursday',
		'Friday',
		'Saturday',
	],
	it: [
		'Domenica',
		'Lunedì',
		'Martedì',
		'Mercoledì',
		'Giovedì',
		'Venerdì',
		'Sabato',
	],
	vi: [
		'Thứ Hai',
		'Thứ Ba',
		'Thứ Tư',
		'Thứ Năm',
		'Thứ Sáu',
		'Thứ Bảy',
		'Chủ Nhật',
	],
};

exports.WEEKDAYS_SHORT = {
	en: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
	it: ['D', 'L', 'M', 'M', 'G', 'V', 'S'],
	vi: ['CN', 'T2', 'T3', 'T4', 'T5', 'T6', 'T7'],
};

exports.MONTHS = {
	en: [
		'January',
		'February',
		'March',
		'April',
		'May',
		'June',
		'July',
		'August',
		'September',
		'October',
		'November',
		'December',
	],
	it: [
		'Gennaio',
		'Febbraio',
		'Marzo',
		'Aprile',
		'Maggio',
		'Giugno',
		'Luglio',
		'Agosto',
		'Settembre',
		'Ottobre',
		'Novembre',
		'Dicembre',
	],
	vi: [
		'Tháng 1',
		'Tháng 2',
		'Tháng 3',
		'Tháng 4',
		'Tháng 5',
		'Tháng 6',
		'Tháng 7',
		'Tháng 8',
		'Tháng 9',
		'Tháng 10',
		'Tháng 11',
		'Tháng 12',
	],
};

exports.FIRST_DAY = {
	en: 0,
	it: 1, // Use Monday as first day of the week
	vi: 1,
};
