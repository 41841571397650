import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ErrorMessage } from 'formik';
import { injectIntl } from 'react-intl';

import FingerprintTypes from 'const/fingerprintTypes';
import CustomErrMsg from '../../common/CustomErrMsg';
import FormikMuiInput from '../FormikMuiInput';
import FormikAutoComplete from '../FormikAutoComplete';
import './style.scss';
import messages from './messages';

const handleKeyPress = event => {
	if (event.charCode < 48 || event.charCode > 57) {
		event.preventDefault();
	}
};

const fingerTypeItems = FingerprintTypes.map(item => ({
	label: item,
	value: item,
}));

const MuiFingerprintField = props => {
	const {
		intl,
		label,
		fingerIndex,
		handSide,
		errorMessages,
		className,
	} = props;

	const noresultText = intl.formatMessage(messages.no_result);
	const placeholderText = intl.formatMessage(
		messages.placeholder_fingerprinttype
	);

	const fingerName = `${handSide}${fingerIndex}`;
	const fingerType = `${handSide}t${fingerIndex}`;

	return (
		<div className={classNames('fingerprintifield', className)}>
			<label className="lb-t-right required" htmlFor={fingerName}>
				{label}
			</label>
			<FormikMuiInput
				name={fingerName}
				className=""
				type="number"
				onKeyPress={handleKeyPress}
			/>
			<FormikAutoComplete
				className="inline-field suggest-input"
				name={fingerType}
				items={fingerTypeItems}
				placeholder={placeholderText}
				noOptionText={noresultText}
			/>

			<div>&nbsp;</div>
			<ErrorMessage
				component={CustomErrMsg}
				intl={intl}
				messages={errorMessages}
				name={fingerName}
				className="error point-error"
			/>
			<ErrorMessage
				component={CustomErrMsg}
				intl={intl}
				messages={errorMessages}
				name={fingerType}
				className="error type-error"
			/>
		</div>
	);
};

MuiFingerprintField.propTypes = {
	intl: PropTypes.any.isRequired,
	label: PropTypes.string.isRequired,
	fingerIndex: PropTypes.number.isRequired,
	handSide: PropTypes.oneOf(['l', 'r']).isRequired,
	errorMessages: PropTypes.any.isRequired,
	className: PropTypes.string,
};

MuiFingerprintField.defaultProps = {};

export default injectIntl(MuiFingerprintField);
