import React from 'react';
import PropTypes from 'prop-types';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { Field } from 'formik';
import { injectIntl } from 'react-intl';

import messages from './messages';

const FormikMuiSexSelect = props => {
	const { intl, name, className, ...rest } = props;
	const empty = intl.formatMessage(messages.option_empty);
	const female = intl.formatMessage(messages.option_female);
	const male = intl.formatMessage(messages.option_male);

	return (
		<Field name={name}>
			{({
				field, // { name, value, onChange, onBlur }
				form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
				meta,
			}) => (
				<FormControl variant="outlined" className={className}>
					<Select
						id={name}
						inputProps={{
							id: 'outlined-age-simple',
						}}
						{...rest}
						{...field}
					>
						<MenuItem value="">
							<em>{empty}</em>
						</MenuItem>
						<MenuItem value={male}>{male}</MenuItem>
						<MenuItem value={female}>{female}</MenuItem>
					</Select>
				</FormControl>
			)}
		</Field>
	);
};

FormikMuiSexSelect.propTypes = {
	intl: PropTypes.any.isRequired,
	name: PropTypes.string.isRequired,
	className: PropTypes.string,
};

FormikMuiSexSelect.defaultProps = {
	className: null,
};

export default injectIntl(FormikMuiSexSelect);
