import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import { Field } from 'formik';

const FormikMuiInput = props => {
	const { name, ...rest } = props;

	return (
		<Field name={name}>
			{({
				field, // { name, value, onChange, onBlur }
				form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
				meta,
			}) => (
				<TextField
					id={name}
					variant="outlined"
					inputProps={{ 'aria-label': 'bare' }}
					{...rest}
					{...field}
				/>
			)}
		</Field>
	);
};

FormikMuiInput.propTypes = {
	name: PropTypes.string.isRequired,
	className: PropTypes.string,
	type: PropTypes.string,
};
FormikMuiInput.defaultProps = {
	className: '',
	type: 'text',
};

export default FormikMuiInput;
