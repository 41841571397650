import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Spinner from 'react-bootstrap/Spinner';
import './style.scss';

const Loading = ({ loading }) =>
	loading ? (
		<div className="overlay d-flex justify-content-center align-items-center bg-secondary">
			<Spinner animation="border" role="status" className="text-light">
				<span className="sr-only">Loading...</span>
			</Spinner>
		</div>
	) : null;

const mapStateToProps = state => ({
	loading: state.reduxState.get('requestSending'),
});

Loading.propTypes = {
	loading: PropTypes.bool,
};

Loading.defaultProps = {
	loading: false,
};

export default connect(
	mapStateToProps,
	null
)(Loading);
