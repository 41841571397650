import cookie from 'js-cookie';
import { UI_CONST } from '../const/url';

const cookieSrv = {
	get xsrfToken() {
		return cookie.get('XSRF-TOKEN');
	},

	set xsrfToken(xsrfToken) {
		cookie.set('XSRF-TOKEN', xsrfToken);
	},

	get userToken() {
		return cookie.get(UI_CONST.TOKEN_LOGGED_IN);
	},

	set userToken(token) {
		cookie.set(UI_CONST.TOKEN_LOGGED_IN, token, { expires: 30 });
	},

	clearUserToken: () => {
		cookie.remove(UI_CONST.TOKEN_LOGGED_IN);
	},
};

export default cookieSrv;
